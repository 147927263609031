<template>
    <div>
        <h2>Analysis Report</h2>
        <b-container fluid>
           <b-row class="my-1">
               <b-col md="3" >
                    <b-card
                    header="Search Criteria"
                    header-tag="header">
                        <form ref="form" @submit.stop.prevent="Search">
                                <b-row class="my-0">
                                <b-col sm="12" >
                                    <label for="ddlIndustryType">Industry Type :</label>
                                </b-col>
                            </b-row>
                                <b-row class="my-0">
                                <b-col sm="12" >
                                    <b-form-select 
                                            id="ddlIndustryType"  
                                            :options="industryTypeOptions"                                
                                            text-field="englishName"
                                            value-field="id"
                                            v-model="$v.search.industryType.$model" 
                                            :state="validateState('industryType')"
                                            aria-describedby="industryType-feedback" 
                                            @change="industryTypeChanged">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                         <b-form-invalid-feedback id="industryType-feedback">
                                            This is a required field
                                        </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <label for="ddlBusinessSector">Business Sector :</label>
                                    </b-col>
                            </b-row>
                                <b-row class="my-0">
                                    <b-col sm="12" >
                                        <b-form-select 
                                            id="ddlBusinessSector" 
                                            :options="businessSectorOptions"                                
                                            text-field="englishName"
                                            :disabled="search.industryType==null"
                                             v-model="$v.search.businessSector.$model" 
                                            :state="validateState('businessSector')"
                                            aria-describedby="businessSector-feedback" 
                                            value-field="id">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback id="businessSector-feedback">
                                            This is a required field
                                        </b-form-invalid-feedback>
                                    </b-col>
                            </b-row>
                                <b-row class="my-3">
                                    <b-col sm="12" >
                                        <b-button  type="submit" variant="primary"  block > Search </b-button>
                                    </b-col>
                            </b-row>
                        </form>
                    </b-card>
               </b-col>
               <!-- <horizontal-scroll> -->
                   <b-col sm="9">
                     <zingchart :data="chartData" ref="chart" ></zingchart>
                   </b-col>
           </b-row>
        </b-container>
    </div>
</template>

<script>
import 'zingchart/zingchart-es6.min.js';
import zingchartVue from 'zingchart-vue';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    components: {
        zingchart: zingchartVue,
    },
    computed: {
        surveyYear(){
            return this.$store.state.userDetails.surveyYear;
        },
    },
    data(){
        return{
            allIndustryTypes:[],
            industryTypeOptions:[],
            allBusinessSectors:[],
            businessSectorOptions:[],
            search:{
                industryType:null,
                businessSector:null,
            },
             chartData: {
                 type: 'line',
                 "plotarea": {
                        "adjust-layout": true
                    },
                "scale-x": {
                        "label": {
                        "text": "Above is an example of a category scale",
                    
                        },
                        "labels": [
                        // "Jan",
                        // "Feb",
                        // "March",
                        // "April",
                        // "May",
                        // "June",
                        // "July",
                        // "Aug"
                        ]
                    },
                    "series": [
                    //     { 
                    //     "values": [
                    //     20,
                    //     40,
                    //     25,
                    //     50,
                    //     15,
                    //     45,
                    //     33,
                    //     34
                    // ]
                    // },
                    // {
                    // "values": [
                    //     5,
                    //     30,
                    //     21,
                    //     18,
                    //     59,
                    //     50,
                    //     28,
                    //     33
                    // ]
                    // }
                ]
             }
        }
    },
    validations:{
        search:{
            industryType:{
                required
            },
             businessSector:{
                required
            },
        }
    },
    methods:{ 
        Search(){
             this.$v.search.$touch();
            if (this.$v.search.$anyError) {
                return;
            }

            let params= {
                        industryType:this.search.industryType,
                        businessSector:this.search.businessSector
                    }
            // console.log(data);
           
            this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/AnalysisReport`,
                    headers: {'Content-Type': 'application/json' },
                    params:params
                })
                .then((response)=>{
                    // console.log(response)
                    if(JSON.parse(response.data.status) == true)
                    {
                        let yearsList =  response.data.reportDetails
                                .map(p=>p.surveyYear)
                                .filter((item, i, ar) => ar.indexOf(item) === i);
                        let maxValue = ( response.data.reportDetails
                                    .map(p=>p.min).count) +
                                    ( response.data.reportDetails
                                    .map(p=>p.median).count) +
                                    ( response.data.reportDetails
                                    .map(p=>p.average).count) +
                                    ( response.data.reportDetails
                                    .map(p=>p.max).count);

                         this.$refs.chart.data = {
                                type: 'line',
                                backgroundColor: '#fff',
                                plot: {
                                    "adjust-layout": true
                                },
                                scaleX: {
                                    values:yearsList, //['Cats', 'Dogs', 'Fish', 'Birds'],
                                    guide: {
                                    visible: false
                                    },
                                    item: {
                                    fontColor: '#999'
                                    },
                                    lineColor: 'none',
                                    lineWidth: '0px',
                                    tick: {
                                    visible: false
                                    }
                                },
                                scaleY: {
                                    guide: {
                                    lineStyle: 'solid'
                                    },
                                    item: {
                                    fontColor: '#999'
                                    },
                                    lineColor: 'none',
                                    lineWidth: '0px',
                                    maxValue: maxValue,//'20px',
                                    minValue: '0px',
                                    step: maxValue/100,
                                    tick: {
                                    visible: false
                                    }
                                },
                                 tooltip: {
                                    text: "%v %t",
                                    // padding: '0px',
                                    // backgroundColor: 'none',
                                    // htmlMode: true,
                                    // placement: 'node:center'
                                },
                                title: {
                                        text: 'Market Pay Trend Analysis',
                                        fontWeight: 'normal',
                                        height: '40px',
                                        textColor: '#ffffff'
                                    },
                                    legend: {
                                            backgroundColor: 'none',
                                            borderColor: 'none',
                                            item: {
                                            fontColor: '#333'
                                            },
                                            layout: 'float',
                                            shadow: false,
                                            width: '90%',
                                            x: '30%',
                                            y: '5%'
                                        },
                        }
                         let series = [];
                         let min = []
                         let average = []
                         let median = []
                         let max = []
                          response.data.reportDetails.forEach((rpt)=>{
                              min.push(rpt.min);
                              average.push(rpt.average);
                              median.push(rpt.median);
                              max.push(rpt.max);
                          });

                          series.push({
                                values:min,
                                alpha: 1,
                                backgroundColor: '#abcdef',
                                hoverState: {
                                    backgroundColor: '#abcdef' 
                                },
                                text:'Min'
                            });
                            series.push({
                                values:average,
                                alpha: 1,
                                backgroundColor: '#123456',
                                hoverState: {
                                    backgroundColor: '#123456' 
                                },
                                text:'Average'
                            })
                            series.push({
                                values:median,
                                alpha: 1,
                                backgroundColor: '#5fddee',
                                hoverState: {
                                    backgroundColor: '#5fddee' 
                                },
                                text:'Median'
                            })
                            series.push({
                                values:max,
                                alpha: 1,
                                backgroundColor: '#ccaadd',
                                hoverState: {
                                    backgroundColor: '#ccaadd' 
                                },
                                text:'Max'
                            });
                            this.$refs.chart.series = series;

                          
                    }
                    else
                         this.reportGeneratorResult = null;
                });
        },
         industryTypeChanged(item){
            if(this.allBusinessSectors!=null && this.allBusinessSectors.length > 0){
                this.businessSectorOptions = this.allBusinessSectors.filter(c=>c.industryTypeId == item);
            }
        },
          validateState(name) {
            const { $dirty, $error } = this.$v.search[name];
            return $dirty ? !$error : null;
        },
    },
     mounted(){
        this.$axios({
                method: 'get',
                url: `${this.$baseURL}/AnalysisReport/lookups`,
            })
            .then((response)=>{
                // console.log(response.data.lookups);
                if(JSON.parse(response.data.status) == true){
                    this.industryTypeOptions = response.data.lookups.industryTypes
                    this.allBusinessSectors = response.data.lookups.businessSectors
                }
            });
    }
}
</script>

<style>

</style>